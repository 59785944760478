import { Helmet } from 'react-helmet';
import React from 'react';
import { graphql } from 'gatsby';

const Detail = ({ data }) => {

  //  Page
  const post = data.redirectRedirect
  let redirect = `${post.redirect_redirect.uri.toLowerCase().replace('internal:', '')}`

  return (
    <Helmet>
      <title>{`Genesis -> ${redirect}`}</title>
      <meta http-equiv="refresh" content={`0; URL='${redirect}'`} />
    </Helmet>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    # All redirects
    redirectRedirect(id: { eq: $id }) {
      id
      redirect_source {
        path
      }
      redirect_redirect {
        uri
      }
    }
  }
`